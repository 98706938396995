import request from '@/common/request/request'

function getOne(params) {
  return request({
    url: '/Mtlk/GetOne',
    method: 'get',
    params
  })
}

export default {
  getOne
}
